export const config = {
  apiKey: "AIzaSyCRu1u6pyNmHtmLNdDqJdyRG7_sFyJmR94",
  authDomain: "kodkafa-firebase.firebaseapp.com",
  databaseURL: "https://kodkafa-firebase.firebaseio.com",
  projectId: "kodkafa-firebase",
  storageBucket: "kodkafa-firebase.appspot.com",
  messagingSenderId: "56570240533",
  appId: "1:56570240533:web:2a414bf7a004ed57fbf81c",
  measurementId: "G-JRG76375PH"
};
